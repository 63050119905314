import {
  Button,
  Dialog,
  GradientHeading,
  Heading,
  Paragraph,
  Stack,
} from "@jt/ui";

interface RegisterInfoPopupProperties {
  onClose: () => void;
}
function RegisterInfoPopup({ onClose }: RegisterInfoPopupProperties) {
  return (
    <Dialog
      hasCloseButton
      headerBanner={<img alt="Register" src="/images/RegisterBanner.png" />}
      isOpen
      maxWidth="lg"
      onClose={onClose}
      titleClassName="mb-0"
      titleIcon={<img alt="JT Logo" src="/images/JT_ID_Logo.png" width={140} />}
    >
      <div className="mx-auto md:w-5/6">
        <GradientHeading as="h4">Your key to all things JT</GradientHeading>
        <Stack className="text-left" spacing={10}>
          <Paragraph size="medium">
            Easily access and manage your services with a JT ID. Plus, our new
            feature allows you to combine Pay Monthly and Pay-As-You-Go
            accounts, providing you with the freedom to easily and seamlessly
            manage multiple JT services, all in one place.
          </Paragraph>
          <div>
            <Heading as="h6">How can I create my JT ID?</Heading>
            <ol className="ml-4 list-decimal">
              <li> Click &apos;Register&apos;</li>
              <li> Fill in your name and email address</li>
              <li> Link your existing JT services to your JT ID.</li>
            </ol>
          </div>
          <Button
            className="mx-auto w-1/2"
            color="primary"
            data-cy="closePopup"
            onClick={onClose}
            size="small"
          >
            OK
          </Button>
        </Stack>
      </div>
    </Dialog>
  );
}

export default RegisterInfoPopup;
