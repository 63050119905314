import { Button, Dialog, Heading, Paragraph, Stack } from "@jt/ui";
import { CookiesMachineContext } from "@/components/machines/cookiesMachine/CookiesMachineContext";

function NewUserInfoPopup() {
  const cookiesActorReference = CookiesMachineContext.useActorRef();

  function handleClose() {
    cookiesActorReference.send({
      popup: "Show",
      type: "UPDATE_NEW_USER_POPUP",
    });
  }

  return (
    <Dialog
      dialogClassName="!px-8"
      isOpen
      maxWidth="xl"
      onClose={handleClose}
      title="NEW My JT"
      titleIcon={<img alt="JT Logo" src="/images/JT_Logo.png" width="80" />}
    >
      <Stack className="text-left">
        <Paragraph>
          We&apos;re delighted to share our new customer App and online portal
          with you! You can now combine Pay monthly and Pay-As-You-Go accounts
          to give you the freedom to easily and seamlessly manage multiple JT
          services. Plus so much more!
        </Paragraph>
        <div className="text-primary">
          <Heading as="h6" className="text-lg" tight>
            IMPORTANT:
          </Heading>
          <Paragraph>
            It&apos;s a totally new platform so everyone needs to
            &apos;Register&apos; for their new JT ID.
          </Paragraph>
        </div>
        <div>
          <Heading as="h6" className="text-lg" tight>
            Getting your new digital JT ID:
          </Heading>
          <ol className="ml-4 list-decimal">
            <li>Click &apos;Register&apos;</li>
            <li>Fill in your name and email address</li>
            <li>Link your JT services</li>
          </ol>
        </div>
        <Button
          className="mx-auto w-1/2 md:w-1/3"
          color="primary"
          onClick={handleClose}
          size="small"
        >
          OK
        </Button>
      </Stack>
    </Dialog>
  );
}

export default NewUserInfoPopup;
